.header__login {
    width       : 100%;
    margin      : 0 auto;
    padding-top : 2rem;
    padding-left: 20%;
}

.header__login h3 {
    font-size   : 2.75rem;
    color       : #000;
    font-weight : 900;
    padding-left: 3.5rem;
    margin-top  : -1.5rem;
}

.header__login h2 {
    font-size     : 5rem;
    color         : #000;
    font-weight   : 900;
    font-family   : Edwardian Script ITC;
    /* font-family: Vladimir Script; */
}


.bg_body {
    /* background-image: url(/static/media/background1.2f356eab.svg); */
    background-image   : url(../../assets/background1.svg);
    position           : relative;
    background-repeat  : no-repeat;
    background-size    : cover;
    height             : 100vh;
    background-position: center;

}
.forgotbg_body {
    /* background-image: url(/static/media/background1.2f356eab.svg); */
    background-image: url(../../assets/background2.svg);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;

}
.resetbg_body{
    background-image: url(../../assets/background3.svg);
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        background-position: center;
}
.heading{
    color: #000;
    text-align: center;
}

.img_email {
    /* background-image   : url(../../assets/Login/email.svg) !important; */
    background-repeat  : no-repeat !important;
    background-position: 7px !important;
    /* padding-left       : 30px; */



}

.password__class {
    /* background-image   : url(../../assets/Login/password.svg) !important; */
    background-repeat  : no-repeat !important;
    background-position: 7px !important;
    padding-left       : 30px;
    z-index            : 999 !important;
    

}
/* .otp_class{  
        width: '3rem';  
        height: '3rem';  
        margin: '20px 1rem';  
        font-size: '1rem';  
        border-radius: 4;  
        border: '2px solid rgba(0,0,0,0.3)',

} */

.Form-inputfield .p-inputtext {
    width        : 100% !important;
    padding-left : 11px !important;
    height       : 40px;
    margin-top   : 10px;
    margin-bottom: 5px;
}

.header {
    margin-left: 20%;

}

.form_logo {
    text-align: center;
}

.form_logo2 {
    text-align: center;
}

.shopez-logo
{
    margin-top: -20px;
    width: 56%;
    height: 220px;
}

.heading{
color: #000;
}

.login_container {
    width        : 35%;
    position     : absolute;
    right        : 100px;
    margin       : 0 auto;
    left         : auto;
    top          : 23%;
    border       : 1px solid #E7FAFD;
    background   : #E7FAFD;
    border-radius: 25px;
    padding      : 2.5rem 5rem;
    height       : auto;



}

.Form-inputfield {

    border-color : #E7FAFD;
    font-size    : 0.95rem;
    border-radius: 25px;
    color        : black;
    line-height  : 19px;




}

.w-100 {
    margin-bottom: 5%;

}

.Login_button {
    width           : 100%;
    background-color: #153947 !important;
    color           : white;
    left            : 4px;
    top             : 36px;
    height          : 35px;
    margin-bottom   : 2.7rem !important;
}

.forgot_password {
    color         : red;
    /* margin-left: 180px; */
    width         : 100%;
    text-align   :left;
    line-height   : 27px;
    cursor        : pointer;
}




.image_header {
    margin-left: 100px;
}

.form-check-remember {
    margin-left: 10px;
    margin-top : 10px;

}


.p-password {
    display: unset !important;
}


@media(max-width:768px) {
    .login_container {
        width        : 95%;
        position     : initial;
        right        : 0px;
        margin       : 0 auto;
        left         : auto;
        top          : 0%;
        border       : 1px solid #E7FAFD;
        background   : #E7FAFD;
        border-radius: 20px;
        padding      : 3rem 1rem 5rem 1rem;
        height       : 70%;

    }

    .header__login {
        text-align  : center;
        padding-left: 0px !important;
    }

    .header__login h2 {
        font-size  : 2.5rem;
        color      : #000;
        font-weight: 600;
        font-family: Edwardian Script ITC;
    }
    .header__login h3 {
        font-size: 1.75rem;
        color: #000;
        font-weight: 900;
        padding-left: 0.5rem;
        margin-top: -0.5rem;
    }

    .forgot_password {
        color         : red;
        /* margin-left: 180px; */
        width         : 64%;
        margin-left   : 65%;
        line-height   : 27px;
    }
    .form_logo img{
        width: 15%;
        margin-bottom: 25px;
    }
    .shopez-logo{
        width: 50%;
        margin-top: -10px;
        height: 200px;
    }
}

@media(max-width:400px) {

    .shopez-logo{
        width: 60%;
        margin-top: -10px;
        height: 180px;
    }
}

@media(min-width:1920px) {
    .login_container {
        height: auto;
    }
}
@media(max-width:1200px) and (min-width:1024px){
    .login_container {
        width: 50%;
        position: absolute;
        right: 30px;
        margin: 0 auto;
        left: auto;
        top: 23%;
        border: 1px solid #E7FAFD;
        background: #E7FAFD;
        border-radius: 25px;
        padding: 2.5rem 5rem;
        height: auto;
    }

}.otp-input input {
    width: 50px;
    margin-right: 33px;
    
}